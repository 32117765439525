import './RadioButton.scss';

import { uniqueId } from 'lodash';
import * as React from 'react';

type HTMLInputElementProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

interface Props
  extends Omit<Omit<Omit<HTMLInputElementProps, 'id'>, 'className'>, 'type'> {
  label: string;
  testId?: string;
}

const RadioButton: React.FC<Props> = React.forwardRef((props, ref) => {
  const { label, testId, ...inputProps } = props;

  const inputId = React.useMemo(() => uniqueId('input'), []);

  return (
    <div className="RadioButton">
      <input
        className="RadioButton-input"
        ref={ref}
        type="radio"
        id={inputId}
        data-test-id={testId}
        {...inputProps}
      />

      <label htmlFor={inputId}>{label}</label>
    </div>
  );
});

export default RadioButton;
