import './Input.scss';

import * as React from 'react';

type HTMLInputElementProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export interface Props extends HTMLInputElementProps {
  testId?: string;
}

function isChromeIOS(): boolean {
  return /CriOS\/\d+/.test(navigator.userAgent);
}

/**
 * An abstract input component that can be rendered by another component to
 * connect it to a portion of the store (see checkout/input).
 */
const Input: React.FC<Props> = props => {
  // Provide an invalid value for "autocomplete" attribute to disable it for
  // Chrome on iOS (because Chrome's autofill doesn't trigger "onChange"):
  // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
  const autoComplete =
    props.autoComplete ?? (isChromeIOS() ? 'disabled' : undefined);

  const { testId, ...inputProps } = props;

  return (
    <input
      {...inputProps}
      className="Input"
      autoComplete={autoComplete}
      data-test-id={testId}
    />
  );
};

Input.defaultProps = {
  type: 'text'
};

export default Input;
