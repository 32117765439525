import './styles.scss';

import React, { PureComponent } from 'react';
import { range } from 'lodash';
import { dateMergingComponents, monthNames } from 'utils/dates';
import classNames from 'classnames';

const CURRENT_YEAR = new Date().getFullYear();

type Props = {
  value: Date;
  disabled?: boolean;
  onChange(date: Date): void;
  inPosterBuilder?: boolean;
};

/**
 * Renders three dropdowns to select a date.
 */
export default class DateSelect extends PureComponent<Props> {
  handleChange(
    component: 'day' | 'month' | 'year' | 'hour' | 'minute' | 'period',
    event: React.ChangeEvent<HTMLSelectElement>
  ) {
    this.props.onChange(
      dateMergingComponents(this.props.value, {
        [component]: parseInt(event.currentTarget.value)
      })
    );
  }

  render() {
    const date = this.props.value;
    const { disabled } = this.props;

    return (
      <div
        className={classNames('fields', {
          'fields--inPosterBuilder': this.props.inPosterBuilder
        })}
      >
        <Select
          label="Birth Month"
          value={String(date.getUTCMonth())}
          disabled={disabled}
          testId="birthMonth"
          onChange={this.handleChange.bind(this, 'month')}
        >
          {monthNames.map((monthName, index) => {
            return (
              <option value={index} key={index}>
                {monthName}
              </option>
            );
          })}
        </Select>
        <Select
          label="Day"
          value={String(date.getUTCDate())}
          disabled={disabled}
          testId="birthDay"
          onChange={this.handleChange.bind(this, 'day')}
        >
          {range(1, 32).map(day => {
            return (
              <option value={day} key={day}>
                {day}
              </option>
            );
          })}
        </Select>
        <Select
          label="Year"
          value={String(date.getUTCFullYear())}
          disabled={disabled}
          testId="birthYear"
          onChange={this.handleChange.bind(this, 'year')}
        >
          {range(2000, CURRENT_YEAR + 1).map(year => {
            return (
              <option value={year} key={year}>
                {year}
              </option>
            );
          })}
        </Select>
        <div className="poster-builder-spacer" />
        <Select
          label="Hour"
          value={String(date.getUTCHours() % 12 || 12)}
          disabled={disabled}
          testId="birthHour"
          onChange={this.handleChange.bind(this, 'hour')}
        >
          {range(1, 13).map(hour => {
            return (
              <option value={hour} key={hour}>
                {hour}
              </option>
            );
          })}
        </Select>
        <Select
          label="Minute"
          value={String(date.getUTCMinutes())}
          disabled={disabled}
          testId="birthMinute"
          onChange={this.handleChange.bind(this, 'minute')}
        >
          {range(0, 60).map(minute => {
            return (
              <option value={minute} key={minute}>
                {minute}
              </option>
            );
          })}
        </Select>
        <Select
          label="Period"
          value={String(date.getUTCHours() < 12 ? 0 : 1)}
          disabled={disabled}
          testId="birthPeriod"
          onChange={this.handleChange.bind(this, 'period')}
        >
          <option value={0}>AM</option>
          <option value={1}>PM</option>
        </Select>
      </div>
    );
  }
}

interface SelectProps {
  label: string;
  value: string;
  disabled?: boolean;
  children: React.ReactNode;
  testId?: string;
  onChange(event: React.ChangeEvent<HTMLSelectElement>): void;
}

const Select: React.FC<SelectProps> = props => {
  const { label, value, disabled, testId, onChange, children } = props;

  return (
    <div className="field">
      <label className="CoreDateSelect-label">{label}</label>
      <select
        className="ui dropdown CoreDateSelect"
        value={value}
        disabled={disabled}
        data-test-id={testId}
        onChange={onChange}
      >
        {children}
      </select>
    </div>
  );
};
