import './styles.scss';

import * as React from 'react';

type Props = {
  size: string;
  strokeWidth: string;
};

function Loader(props: Props) {
  return (
    <div className="Loader">
      <div
        className="Loader-indicator"
        style={{ fontSize: props.size, borderWidth: props.strokeWidth }}
      />
    </div>
  );
}

Loader.defaultProps = { size: '32px', strokeWidth: '3px' };

export default Loader;
