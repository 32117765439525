/**
 * Some convenience functions, types, and constants for working with dates.
 *
 * Date components allow representing a date by year, month... pieces. They're used to alter dates
 * by changing one component.
 *
 * Components use 12 hour time with a 'period' (am = 0, pm = 1).
 *
 * All components are just numbers even though they could be typed more specifically. The overhead
 * of handling each component's type separately kind of defeats the purpose.
 *
 * The 'fragment' is a separate type so we can in some cases apply a partial set of attributes, and
 * in others be sure we can generate a complete date. Maybe there's a better way to define these
 * types. Maybe the complete one should have the more precise name.
 */

type DateComponents = {
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  period: number;
};

type DateComponentsFragment = {
  year?: number;
  month?: number;
  day?: number;
  hour?: number;
  minute?: number;
  period?: number;
};

/**
 * Construct a new date using date components in UTC. There might be a better way to do this.
 */
export function buildUTCDate(
  year: number,
  month: number,
  day: number,
  hours: number = 0,
  minutes: number = 0
): Date {
  const date = new Date();

  date.setUTCFullYear(year, month, day);
  date.setUTCHours(hours, minutes, 0, 0);

  return date;
}

/**
 * Construct a new date by changing some components, e.g. replacing the year or month.
 */
export function dateMergingComponents(
  date: Date,
  components: DateComponentsFragment
): Date {
  const oldComponents = getDateComponents(date);
  const mergedComponents = {
    year: valueOrDefault(components.year, oldComponents.year),
    month: valueOrDefault(components.month, oldComponents.month),
    day: valueOrDefault(components.day, oldComponents.day),
    hour: valueOrDefault(components.hour, oldComponents.hour),
    minute: valueOrDefault(components.minute, oldComponents.minute),
    period: valueOrDefault(components.period, oldComponents.period)
  };
  return dateFromComponents(mergedComponents);
}

/**
 * Return a hash of year, month, and day from a date.
 */
export function getDateComponents(date: Date): DateComponents {
  return {
    year: date.getUTCFullYear(),
    month: date.getUTCMonth(),
    day: date.getUTCDate(),
    hour: date.getUTCHours() % 12 || 12,
    minute: date.getUTCMinutes(),
    period: date.getUTCHours() > 11 ? 1 : 0
  };
}

/**
 * Construct a new date from a complete set of components.
 */
function dateFromComponents(components: DateComponents): Date {
  let { hour } = components;

  if (components.period === 1 && components.hour < 12) {
    hour = components.hour + 12;
  } else if (components.period === 0 && components.hour === 12) {
    hour = 0;
  }

  return buildUTCDate(
    components.year,
    components.month,
    components.day,
    hour,
    components.minute
  );
}

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

function valueOrDefault<type>(
  value: type | null | undefined,
  defaultValue: type
): type {
  if (value != null) {
    return value;
  } else {
    return defaultValue;
  }
}
