declare global {
  interface Window {
    ENV: {
      googleAnalyticsId?: string;
      stripePublishableKey?: string;
      bookPrice: string;
      posterPrice: string;
      giftCertificatePhysicalVariantPremium: string;
    };
  }
}

const { googleAnalyticsId, stripePublishableKey } = window.ENV;

const bookPrice = parseFloat(window.ENV.bookPrice);
const posterPrice = parseFloat(window.ENV.posterPrice);
const giftCertificatePhysicalVariantPremium = parseFloat(
  window.ENV.giftCertificatePhysicalVariantPremium
);

export {
  googleAnalyticsId,
  stripePublishableKey,
  bookPrice,
  posterPrice,
  giftCertificatePhysicalVariantPremium
};
