import axios from 'axios';
import { forEach, snakeCase, camelCase } from 'lodash';

const csrfTokenElement = document.querySelector('meta[name="csrf-token"]');
const csrfToken = csrfTokenElement?.getAttribute('content');

export default axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-CSRF-Token': csrfToken
  },
  transformRequest: [
    (data): string => {
      return JSON.stringify(deepTransformKeys(snakeCase, data));
    }
  ],
  transformResponse: [
    function (data) {
      if (data.length > 0) {
        return deepTransformKeys(camelCase, JSON.parse(data));
      } else {
        return null;
      }
    }
  ]
});

function deepTransformKeys<O extends unknown>(
  transformKey: (key?: string | undefined) => string,
  object: O
): O {
  if (Array.isArray(object)) {
    return (object as any).map((o: any) => deepTransformKeys(transformKey, o));
  } else if (object != null && typeof object === 'object') {
    const transformedObject = {};

    forEach(object as Record<string, unknown>, (value, key) => {
      // Skip customData for now because the book builder expects its keys to be
      // camel case.
      if (key === 'customData') {
        transformedObject[transformKey(key)] = value;
      } else {
        transformedObject[transformKey(key)] = deepTransformKeys(
          transformKey,
          value
        );
      }
    });

    return transformedObject as any;
  } else {
    return object;
  }
}
