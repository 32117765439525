import './styles.scss';

import * as React from 'react';

import { uniqueId } from 'lodash';

interface Props {
  name?: string;
  label?: string;
  checked: boolean;
  disabled?: boolean;
  testId?: string;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  onBlur?(event: React.FocusEvent<HTMLInputElement>): void;
}

const Checkbox: React.FC<Props> = props => {
  const { name, label, checked, onChange, onBlur, testId, disabled } = props;

  const id = React.useMemo(() => uniqueId('checkbox-'), []);

  return (
    <div className="Checkbox">
      <input
        className="Checkbox-input"
        id={id}
        name={name}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        data-test-id={testId}
        onChange={onChange}
        onBlur={onBlur}
      />

      <label htmlFor={id}>{label}</label>
    </div>
  );
};

Checkbox.defaultProps = {
  disabled: false
};

export default Checkbox;
