import { googleAnalyticsId } from 'environment';

import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps {}

class TrackPageView extends React.PureComponent<Props> {
  componentDidUpdate(prevProps: Props) {
    if (
      window.gtag != null &&
      googleAnalyticsId != null &&
      this.props.location !== prevProps.location
    ) {
      window.gtag('config', googleAnalyticsId, {
        page_path: location.pathname
      });

      window.gtag('event', 'page_view');
    }
  }

  render() {
    return null;
  }
}

export default withRouter(TrackPageView);
